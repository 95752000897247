<template>
    <div>
        <h1 class="page-title mb-4">Passwort zurücksetzen</h1>

        <template v-if="!token">
            <v-form ref="passwordResetForm" @submit.prevent="requestPasswordReset">
                <v-text-field
                    v-model="email"
                    required
                    outlined
                    label="E-Mail-Adresse"
                    autocomplete="username"
                    :rules="[formRules.required('Deine E-Mail-Adresse'), formRules.valid.email]"
                />

                <v-btn :loading="loading" color="primary" large block depressed class="mb-3" type="submit">
                    Weitere Anweisungen per Mail senden
                </v-btn>
            </v-form>
        </template>

        <template v-else>
            <v-form ref="setNewPasswordForm" @submit.prevent="setNewPassword">
                <v-text-field
                    v-model="password"
                    required
                    outlined
                    autocomplete="off"
                    label="Passwort"
                    :rules="[formRules.required('Dein neues Passwort')]"
                    :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="toggleShowPassword()"
                />

                <v-btn :loading="loading" color="primary" large block depressed class="mb-3" type="submit">
                    Neues Passwort setzen
                </v-btn>
            </v-form>
        </template>

        <router-link :to="{ name: 'Login' }" class="caption">Anmelden</router-link>

        <confirm-dialog ref="confirm" />
    </div>
</template>

<script>
import api from "../plugins/api";
import { sendMessage } from "../plugins/messageBus";
import formRules from "../plugins/formRules";
import ConfirmDialog from "../components/ConfirmDialog.vue";

export default {
    name: "PasswordReset",

    components: {
        ConfirmDialog,
    },

    props: {
        token: {
            type: String,
            required: false,
            default: "",
        },
    },

    data: () => ({
        email: "",
        password: "",
        showPassword: false,
        formRules,
        loading: false,
    }),

    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },

        showConfirmDialog(message, redirect) {
            this.$refs.confirm.open(message, { choice: false }).then(() => {
                this.loading = false;
                if (redirect) this.$router.push(redirect);
            });
        },

        async requestPasswordReset() {
            if (!this.$refs.passwordResetForm.validate()) return;

            this.loading = true;
            try {
                await api.requestPasswordReset(this.email);

                this.showConfirmDialog(
                    "Wir haben Dir eine E-Mail mit weiteren Anweisungen gesendet, wie Du Dein Passwort zurücksetzen kannst.",
                    { name: "Login" },
                );
            } catch (error) {
                sendMessage("Entschuldigung, das hat leider nicht geklappt. Bitte versuche es später erneut.", "error");
            }
            this.loading = false;
        },

        async setNewPassword() {
            if (!this.$refs.setNewPasswordForm.validate()) return;

            this.loading = true;
            try {
                await api.resetPassword(this.token, this.password);

                this.showConfirmDialog(
                    "Prima, das hat geklappt! Du kannst Dich jetzt mit Deinem neuen Passwort anmelden.",
                    { name: "Login" },
                );
            } catch (error) {
                sendMessage("Entschuldigung, das hat leider nicht geklappt. Bitte versuche es später erneut.", "error");
            }
            this.loading = false;
        },
    },
};
</script>
