<template>
    <v-dialog v-model="dialog" max-width="450" @keydown.esc="cancel()">
        <v-card class="pa-4">
            <v-card-text class="pt-4 pb-0">
                <p class="title text-center">
                    {{ message }}
                </p>
            </v-card-text>
            <v-card-actions>
                <v-layout v-if="options.choice">
                    <v-flex xs-6>
                        <v-btn block text color="error" @click.native="cancel()">Nein</v-btn>
                    </v-flex>
                    <v-flex xs-6>
                        <v-btn block depressed color="primary" @click.native="agree()">Ja</v-btn>
                    </v-flex>
                </v-layout>

                <v-layout v-else>
                    <v-flex xs-3 />
                    <v-flex xs-6>
                        <v-btn block depressed color="primary" @click.native="agree()">OK</v-btn>
                    </v-flex>
                    <v-flex xs-3 />
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        options: {
            color: "primary",
            choice: true,
        },
    }),

    methods: {
        open(message, options) {
            this.message = message;
            this.options = Object.assign(this.options, options);
            this.dialog = true;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        agree() {
            this.resolve(true);
            this.dialog = false;
        },

        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>
