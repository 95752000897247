<template>
    <div>
        <h1 class="page-title mb-4">Account aktivieren</h1>

        <v-form ref="activateform" @submit.prevent="performActivation">
            <p class="mb-8 text-center">
                Um deinen Account zu aktivieren, gib bitte Deine E-Mail-Adresse ein und wähle ein sicheres Passwort.
                <br />
                <br />
                Die E-Mail-Adresse ist jene, die Du für die Anmeldung zum MoVe verwendet hast.
            </p>

            <div class="login-hint pa-3 mb-5 text-center">
                Du hast deinen Account schon aktiviert?<br />
                <router-link :to="{ name: 'Login' }">Hier geht es zur Anmeldung</router-link>
            </div>

            <v-text-field
                v-model="email"
                required
                outlined
                label="E-Mail-Adresse"
                :placeholder="!autofilled ? ' ' : null"
                autocomplete="username"
                :rules="[formRules.required('Deine E-Mail-Adresse'), formRules.valid.email]"
            />
            <v-text-field
                v-model="password"
                required
                outlined
                autocomplete="new-password"
                label="Passwort"
                :placeholder="!autofilled ? ' ' : null"
                :rules="[formRules.required('Dein Passwort')]"
                :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="toggleShowPassword()"
            />

            <v-btn :loading="loading" color="primary" large block depressed class="mb-3" type="submit">
                Account aktivieren
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import api from "../plugins/api";
import { sendMessage } from "../plugins/messageBus";
import formRules from "../plugins/formRules";

export default {
    name: "Activate",

    props: {
        validationToken: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        autofilled: false,
        email: "",
        password: "",
        showPassword: false,
        formRules,
        loading: false,
    }),

    watch: {
        email() {
            this.autofilled = true;
        },
    },

    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },

        async performActivation() {
            if (!this.$refs.activateform.validate()) return;

            this.loading = true;
            try {
                await api.activate(this.email, this.password, this.validationToken);
                sendMessage("Dein Account wurde aktiviert. Viel Spaß mit der MoVe App!", "success");
                this.$router.push({ name: "Login", params: { prefilledEmail: btoa(this.email) } });
            } catch (error) {
                if (error.response.data.error === "wrong_credentials") {
                    sendMessage("Bitte überprüfe deine E-Mail-Adresse und versuche es erneut.", "error");
                } else {
                    sendMessage(
                        "Entschuldigung, das hat leider nicht geklappt. Bitte versuche es später erneut.",
                        "error",
                    );
                }
            }
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.login-hint {
    border: 1px solid var(--v-primary-base);
    border-radius: 4px;
}
</style>
